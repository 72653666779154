export const actionsDataTableProps = {
  collections: {
    title: 'Collections',
    ajaxURL:
      '/api/collections/?format=json&fields=id,collection_id,hf_created,sender,recipient,collection_origin,collection_destination',
    addURLs: [
      {
        name: 'Create Collection',
        url: '/navigation/logistics/collections/new/',
      },
    ],
    exportURL: '/deliveries/export_collection/',
    columns: [
      {
        title: 'Id',
        formatter: function (cell) {
          let data = cell.getRow().getData();
          return `<span class="d-block">${data.collection_id}</span>
              <span class="d-block text-muted">${data.hf_created}</span>
              `;
        },
      },
      {
        title: 'Sender',
        formatter: function (cell) {
          let data = cell.getRow().getData().sender;
          return `<span class="d-block">${data.name}</span>
              <span class="d-block text-muted">${data.primary_phone}</span>
              `;
        },
      },
      {
        title: 'Recipient',
        formatter: function (cell) {
          let data = cell.getRow().getData().recipient;
          return `<span class="d-block">${data.name}</span>
              <span class="d-block text-muted">${data.primary_phone}</span>
              `;
        },
      },
      {
        title: 'From',
        formatter: function (cell) {
          let data = cell.getRow().getData().collection_origin;
          return data.location.address;
        },
      },
      {
        title: 'To',
        formatter: function (cell) {
          let data = cell.getRow().getData().collection_destination;
          return data.location.address;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="/navigation/logistics/collections/${
              cell.getRow().getData().id
            }/">View</a>`;
        },
      },
    ],
    transitionUrl: '/api/collections/transition/multiple/',
    actionsMap: {
      draft: {
        Archive: 'archived',
      },
      confirmed: {
        Complete: 'complete',
      },
      complete: {
        Archive: 'archived',
      },
    },
  },
  deliveries: {
    title: 'Deliveries',
    ajaxURL:
      '/api/delivery-item/?format=json&fields=id,delivery_id,hf_created,sender_details,recipient_details,origin_address,destination_address',
    addURLs: [
      {
        name: 'Create Delivery',
        url: 'deliveries/new/delivery/',
      },
    ],
    exportURL: '/deliveries/export_logistics_order_item/',
    columns: [
      {
        title: 'Id',
        formatter: function (cell) {
          let data = cell.getRow().getData();
          return `<span class="d-block">${data.delivery_id}</span>
              <span class="d-block text-muted">${data.hf_created}</span>
              `;
        },
      },
      {
        title: 'Sender',
        formatter: function (cell) {
          let data = cell.getRow().getData().sender_details;
          return `<span class="d-block">${data.name}</span>
              <span class="d-block text-muted">${data.contact}</span>
              `;
        },
      },
      {
        title: 'Recipient',
        formatter: function (cell) {
          let data = cell.getRow().getData().recipient_details;
          return `<span class="d-block">${data.name}</span>
              <span class="d-block text-muted">${data.contact}</span>
              `;
        },
      },
      { title: 'From', field: 'origin_address' },
      { title: 'To', field: 'destination_address' },
      {
        title: 'Action',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="/navigation/logistics/deliveries/${
              cell.getRow().getData().id
            }/">View</a>`;
        },
      },
    ],
    transitionUrl: '/api/orders/transition/multiple/',
    actionsMap: {
      draft: {
        Confirm: 'ready',
        Archive: 'archived',
      },
    },
  },
  shipments: {
    title: 'Shipments',
    ajaxURL:
      '/api/shipment/?format=json&fields=id,document_identifier,hf_created,branch_name,business_partner_data,origin__address,destination__address',
    addURLs: [],
    exportURL: '/logistics/export_shipment/',
    columns: [
      {
        title: 'Id',
        formatter: function (cell) {
          let data = cell.getRow().getData();
          return `<span class="d-block">${data.document_identifier}</span>
              <span class="d-block text-muted">${data.hf_created}</span>
              `;
        },
      },
      { title: 'Dispatch Branch', field: 'branch_name' },
      {
        title: 'Recipient',
        formatter: function (cell) {
          let data = cell.getRow().getData().partner_details;
          return `<span class="d-block">${data.name}</span>
              <span class="d-block text-muted">${data.contact}</span>
              `;
        },
      },
      { title: 'From', field: 'origin__address' },
      { title: 'To', field: 'destination__address' },
      {
        title: 'Action',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success"
            href="/navigation/logistics/shipments/${cell.getRow().getData().id}/">View</a>`;
        },
      },
    ],
    transitionUrl: '/api/document/shipment/transition/',
    actionsMap: {
      draft: {
        Confirm: 'draft_ready_accepted',
        Archive: 'archived',
      },
      accepted: {
        Dispatch: 'in_progress',
        Cancel: 'rejected',
      },
      failed: {
        Archive: 'archived',
      },
      in_progress: {
        Receive: 'complete',
      },
    },
  },
};
